<template>
  <a @click="$router.go(-1);" style="cursor: pointer; color: #D9D9D9;">
    <ph-arrow-left :size="24"  />
  </a>
</template>

<script>
export default {
  name: "BtnBackPage"
}
</script>

<style scoped>

</style>